import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class PrintJobService
    {
        #api = null;    
        constructor() {
            this.#api = API_URL + 'admin/print-job';
        }

        getByUserNotification(data={},index = null, userNotificationId){
            let url = `${this.#api}/${userNotificationId}/get/by/user-notification`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        uploadFile(data){
            let url = `${this.#api}/upload-file`;
            return apiService.post(url, data)
        }

        getOtherUploads(data={}, index){
            let url = `${this.#api}/other-uploads/get`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        exportBulkPrinter(id){
            let url = `${this.#api}/${id}/export`;
            window.open(url, "_blank");
        }

        delete(id){
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
        }

        deleteMultiple(printJobIds){
            let url = `${this.#api}/delete-multiple`
            return apiService.post(url, printJobIds);
        }

        markAsCompleted(data){
            let url = `${this.#api}/mark-as-complete`;
            return apiService.post(url, data);
        }

        removeFromNotification(id){
            let url = `${this.#api}/${id}/remove-notification`;
            return apiService.put(url);
        }

        getIncompletePrintJobs(data={}, index){
            let url = `${this.#api}/incomplete/get`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }
    }
