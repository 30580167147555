<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>
                                Print queue
                                </h4>
                                <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard </router-link>\ Print queue
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                </div>
                            </div>
                        </div>
                    </div>
                     <div class="card-body">
                        <div class="mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">                            
                                <v-col cols="12" md="12" class="text-right">
                                    <div class="upload-control" v-show="images  && images.length">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="row">
                                                    <div class="col-md-2 text-center" v-for="(image, idx) in images" :key="idx">
                                                        <img :src="image" style="height: 50px; width: 50px; object-fit:cover; position: relative;"/>
                                                        <a href="javascript:;" @click="removeFile(idx)" style="position:absolute; top:9px; right: 50px;">
                                                            <i class="fa fa-times text-white"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5 text-right">
                                                <span class="text-white text-left">Total Files: {{images.length}}</span><br/>
                                                
                                                <v-btn
                                                    class="btn btn-danger mr-3 text-white"
                                                    text
                                                    @click="removeAllFiles"
                                                >Remove
                                                </v-btn>

                                                <v-btn
                                                    type="submit"
                                                    color="btn btn-primary"
                                                    class="btn-standard"
                                                    text
                                                    @click="upload"
                                                    :loading="isBusy"
                                                >Start Upload
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="uploader"
                                        @dragenter="OnDragEnter"
                                        @dragleave="OnDragLeave"
                                        @dragover.prevent
                                        @drop="onDrop"
                                        :class="{ dragging: isDragging }"
                                    >

                                        <div class="pt-5 pb-1">
                                            <i class="fa fa-cloud-upload"></i>
                                            <p>Drag your Files here</p>
                                            <div>OR</div>
                                            <div class="file-input">
                                            <label for="file">Select a file</label>
                                            <input type="file" id="file" @change="onInputChange" multiple/>
                                            </div>
                                            <p>
                                                Only image,pdf and doc files are allowed
                                            </p>
                                        </div>
                                    </div>
                                
                                <div class="my-5 text-right">
                                    <v-btn
                                        color="success"
                                        class="text-white ml-3"
                                        medium
                                        :loading="isMarkAsCompleteLoading"
                                        @click="markAsComplete"
                                        :disabled="print_job_ids.length==0"
                                    >
                                    Mark as Complete
                                    </v-btn>
                                </div>

                                <div class="table-responsive">
                                    <v-skeleton-loader
                                        type="table-thead"
                                        v-if="loading"
                                    >
                                    </v-skeleton-loader>

                                    <v-skeleton-loader
                                        v-if="loading"
                                        type="table-row-divider@25"
                                    >
                                    </v-skeleton-loader>

                                    <table class="table" v-if="!loading">
                                        <thead>
                                            <tr class="text-left">
                                                <th class="px-3">
                                                    <v-checkbox
                                                    v-if="showCheckAllBox"
                                                        @click="checkAllPrintJob"
                                                        v-model="checkAllPrintJobFlag"
                                                    ></v-checkbox>
                                                </th>
                                                <th class="px-3">File Name</th>
                                                <th class="px-3">Options </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template>
                                                <tr v-for="(item, index) in medias" :key="index">
                                                    <td class="px-2">
                                                        <v-checkbox
                                                            v-if="!item.is_completed"
                                                            v-model="print_job_ids"
                                                            :value="item.id"
                                                        ></v-checkbox>
                                                        <span class="ml-4 pl-2 badge badge-success" v-if="item.is_completed">
                                                            Marked as complete
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="font-size-lg">
                                                            {{item.file_name}}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <v-btn
                                                            class="btn btn-primary text-white"
                                                            small
                                                            @click="viewFile(item.file_path)"
                                                        >
                                                            <v-icon small elevation="2" outlined>fas fa-eye</v-icon>&nbsp; View
                                                        </v-btn>
                                                        <v-btn
                                                            color="red"
                                                            small
                                                            @click="deleteFile(item.id)"
                                                            class="text-white ml-2"
                                                        >
                                                            <v-icon small elevation="2" outlined>fas fa-trash-alt</v-icon>&nbsp; Delete
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                <tr v-if="medias.length == 0">
                                                    <td colspan="3" class="text-center">
                                                        No items found
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>

                                    <b-pagination
                                        v-if="medias.length > 0"
                                        class="pull-right mt-7"
                                        @input="getOtherUploads"
                                        v-model="page"
                                        :total-rows="total"
                                        :disabled="loading"
                                        :per-page="perPage"
                                        first-number
                                        last-number
                                    ></b-pagination>
                                    
                                </div>
                                </v-col>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import PrintJobService from "@/services/print-job/PrintJobService";
import {required} from "vuelidate/lib/validators";

const printJob = new PrintJobService();

export default {    
    validations:{
        print_job_ids:{required}
    },
    data(){
        return{
            total: null,
            perPage: null,
            page: null,
            loading: false,
            files: [],
            isDragging: false,
            dragCount: 0,
            error: false,
            images: [],
            medias: [],
            isBusy: false,
            total: null,
            perPage: null,
            page: null,
            checkAllPrintJobFlag: false,
            print_job_ids: [],
            isMarkAsCompleteLoading: false,
        }
    },
    computed:{
        showCheckAllBox(){
            let completedValueExist = this.medias.filter(item => {
                return item.is_completed == 0;
            });
            if(completedValueExist.length == 0)
                return false;
            return true;
        }
    },
    methods:{
        checkAllPrintJob(){
            if(this.checkAllPrintJobFlag){
                this.medias.forEach(item => {
                    this.print_job_ids.push(item.id);
                });
            }
            else{
                this.print_job_ids = [];
            }
        },
        OnDragEnter(e) {
            e.preventDefault();
            this.dragCount++;
            this.isDragging = true;
            return false;
        },
        OnDragLeave(e) {
            e.preventDefault();
            this.dragCount--;

            if (this.dragCount <= 0) this.isDragging = false;
        },
        onInputChange(e) {
            const files = e.target.files;
            Array.from(files).forEach(file => this.addImage(file));
        },
        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();

            this.isDragging = false;

            const files = e.dataTransfer.files;

            Array.from(files).forEach(file => this.addImage(file));
        },
        addImage(file){
            const type = file.name.split(".")[1];
            if (file.type.match("image.*")) {
                this.addFiles(file);
            } else if (type == "pdf") {
                this.addFiles(file);
            } else if (type == "doc") {
                this.addFiles(file);
            } else if (type == "docx") {
                this.addFiles(file);
            } else {
                this.error = true;
                return;
            }
        },
        addFiles(file) {
            this.error = false;
            this.files.push(file);

            const img = new Image(),
                reader = new FileReader();

            reader.onload = e => this.images.push(e.target.result);

            reader.readAsDataURL(file);
        },
        checkFileType(file) {
            let fileExtension = file.split(".")[file.split(".").length-1];
            let image = this.imageFiles.indexOf(fileExtension) > -1;
            return image;
        },
        deleteImg(id) {
            this.$confirm({
                message: `Confirm delete file ?`,
                button: {
                no: "No",
                yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        mediaService.delete(id).then(res => {
                            this.$snotify.success("Deleted");
                            this.getOtherUploads();
                        });
                    }
                }
            });
        },
        showUrl(image) {
            this.url = image.image_path.real;
            this.dialog = true;
        },
        upload() {
            const formData = new FormData();
            this.isBusy = true;
            this.files.forEach(file => {
                if (file) {
                    formData.append("file[]", file);
                    formData.append("size[]", file.size);
                    formData.append("name[]", file.name);
                    formData.append("type[]", file.name.split(".")[1]);
                }
            });

            printJob.uploadFile(formData).then(res => {
                this.isBusy = false;
                this.$snotify.success("File uploaded successfully.");
                this.images = [];
                this.files = [];
                this.getOtherUploads();
            });
        },
        getOtherUploads(){
            this.loading = true;
            printJob
            .getOtherUploads({}, this.page)
            .then((response) => {
                this.medias = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            });
        },
        viewFile(filePath){
            window.open(filePath, "_blank");
        },
        deleteFile(printJobId) {
            this.$confirm({
                message: `Are you sure? `,
                button: {
                no: "No",
                yes: "Yes",
                },
                callback: (confirm) => {
                if (confirm) {
                    printJob
                    .delete(printJobId)
                    .then((response) => {
                        this.getOtherUploads();
                        this.$snotify.success("File Deleted Sucessfully ");
                    })
                    .catch((err) => {
                        this.$snotify.error("Oops something went wrong");
                    });
                }
                },
            });
        },
        removeFile(idx){
            this.images.splice(idx, 1);
            this.files.splice(idx, 1);
        },
        removeAllFiles(){
            this.images = [];
            this.files = [];
        },
        markAsComplete(){
            let data = {
                print_job_ids: this.print_job_ids
            };
            if (this.$v.$error) {
                this.$snotify.error('Minimum of one file is required to continue');
            } else {
                this.isMarkAsCompleteLoading = true;
                printJob
                .markAsCompleted(data)
                .then((response) => {
                    this.$snotify.success(response.data.message);
                    this.isMarkAsCompleteLoading = false;
                    this.print_job_ids = [];
                    this.checkAllPrintJobFlag = false;
                    this.getOtherUploads();
                })
                .catch((err) => {
                    this.$snotify.error('Oops looks like something went wrong.');
                    this.isMarkAsCompleteLoading = false;
                })
                .finally(() => {

                });
            }
        }
    },
    mounted(){
        this.getOtherUploads();
    }
}
</script>

<style lang="scss" scoped>
.uploader {
  width: 100%;
  background: #eef5ff;
  color: #777;
  //padding: 40px 15px 102px - 30px;
  text-align: center;
  border-radius: 10px;
  //border: 1px dashed #696969 !important;
  font-size: 17px;
  position: relative;

  &.dragging {
    background: #fff;
    color: #2196f3;
    border: 1px dashed #696969 !important;

    .file-input label {
      background: #2196f3;
      color: #fff;
    }
  }

  i {
    font-size: 20px;
  }

  .file-input {
    width: 175px;
    margin: auto;
    height: 65px;
    position: relative;

    label,
    input {
      background: #2196f3;
      color: #fff;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      border-radius: 4px;
      margin-top: 7px;
      cursor: pointer;
    }

    input {
      opacity: 0;
      z-index: -2;
    }
  }

  .images-preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 0 10px;



    .add-btn-wrapper {
      width: 100% !important;
      border: 1px dashed;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      order: 0 !important;
    }

    .details {
      font-size: 12px;
      background: #fff;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: self-start;
      padding: 3px 6px;

      .name {
        overflow: hidden;
        height: 18px;
        width: 100%;
      }
    }
  }

  .action-bar {
    width: 100%;
  }

  .action-bar i {
    font-size: 15px;
  }

  .upload-control {
    position: relative;
    width: 88%;
    border: 1px dotted #c1b5b5;
    bottom: 0;
    left: 0;
    padding: 10px;
    padding-bottom: 10px;
    padding-bottom: 4px;
    top: 10px;
    margin: 0 auto;

    button,
    label {
      background: #fff;
      border: 1px solid #7b7b7b;
      border-radius: 3px;
      color: #111;
      font-size: 13px;
      cursor: pointer;
    }

    label {
      padding: 2px 5px;
      margin-right: 10px;
    }
  }
}
</style>

